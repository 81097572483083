import axios from 'axios';
import { AuthService } from '../services/authService';

const authService = new AuthService();
let message:any;

axios.interceptors.response.use(
    (response) => {
      console.log(response,"res");
      return response;
    },
    (error) => {
      console.log(error.response,"err");
      
        switch (error.response.status) {
            case 401:
            authService.logout();
            // alert('Your session expired, please loging again');
            window.location.reload();
            break;
            case 400:
              message=error;

        }
        return Promise.reject(message);
    }
  );
  axios.defaults.headers.common['Authorization'] = `Basic ${localStorage.getItem('session')}`;

  axios.defaults.baseURL = process.env.REACT_APP_ENV === 'development' ? "https://faraday-admin.arachnomesh.com/api/v1/" : process.env.REACT_APP_ENV === 'production' ? "https://admin.fabricationbazar.com/api/v1/" : process.env.REACT_APP_ENV === 'test' ? "https://fb-admin.arachnomesh.com/api/v1/" : "http://localhost:5005/api/v1/";
  // baseURL: 'http://localhost:5005/api/v1/'
  // baseURL: 'https://faraday-admin.arachnomesh.com/api/v1/',

//interceptor


//error in api catch block

export default axios;