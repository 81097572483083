/* eslint-disable */
import { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import logo2 from "../../assets/img/logo.png"

import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
// import landline from '../../assets/img/telephone.png';
// import email from '../../assets/img/email.png';
// import phone from '../../assets/img/phone.png';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import * as yup from 'yup'
import "yup-phone"
import { useFormik } from 'formik';
import { useAppDispatch, useAppSelector } from '../../redux/hook';
import { toast } from 'react-toastify';
import { createCustomer } from '../../redux/actions/loginActions';
// import { constants } from 'fs';
import { useLocation } from 'react-router-dom';


function Registration() {

    const customer: any = useAppSelector((state: any) => state?.customerLogin?.registeredCustomer)
    let id = customer?.response?.customer?.id

    const [email, setEmail] = useState<any>("")
    const [phoneNumber, setPhoneNumber] = useState<any>("")
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const location = useLocation();
    const state: any = location.state
    console.log(state)
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const formik = useFormik({
        initialValues: {
            name: '',
            organization: '',
            email: '',
            phone: '',
            designation: '',
            city: '',
            address: ''
        },

        validationSchema: yup.object({
            name: yup.string().required('Please enter your name'),
            email: yup.string().email('Invalid email address').required('Please enter your email'),
            organization: yup.string().required('Please enter your organization name'),
            phone: yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10).max(10).required('Please enter your phone number'),
        }),
        onSubmit: (values) => {
            // formik.values.phone = phoneNumber
            console.log(values, "values")
            setEmail(values.email)

            if (state) {
                const customer = {
                    "name": state.name,
                    "organization_name": state.organization,
                    "email": state.email,
                    "mobile": state.phone,
                    "city": state.city,
                    "designation": state.designation,
                    "address": state.address,
                    "website": ""
                }
                console.log(customer)
                if (customer) {
                    dispatch(createCustomer(customer))
                }
            } else {
                const customer = {
                    "name": values.name,
                    "organization_name": values.organization,
                    "email": values.email,
                    "mobile": values.phone,
                    "city": values.city,
                    "designation": values.designation,
                    "address": values.address,
                    "website": ""
                }
                console.log(customer)
                if (customer) {
                    dispatch(createCustomer(customer))
                }
            }




        },
    });



    useEffect(() => {

        if (id) {
            console.log(id, "id")
            // toast.success("Registered Successfully")
            navigate('/login', { state: email })
            window.location.reload()
        }
    }, [customer])



    return (
        <div className="register-container2">
            <Row className="main-row">
                <Col className="logo_footer">
                    <div className="login__logo3">
                        <NavLink to='/'> <img src={logo2} alt='logo' /></NavLink>
                    </div>

                    <div className='form-holder'>
                        <Container className='reg-form-container'>
                            <Form onSubmit={formik.handleSubmit}>
                                <h2>Sign up</h2>
                                <p id='reg-welcome'>Welcome, Please enter your details!</p>

                                <Row>
                                    <Form.Group as={Col} className='mb-3'>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control type="text" id='name' placeholder="Enter Name" onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.name : formik.values.name} />
                                        {formik.touched.name && formik.errors.name ? <p className='error'>{formik.errors.name}</p> : null}
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Organisation Name</Form.Label>
                                        <Form.Control type="text" id='organization' placeholder="Enter organization" onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.organization : formik.values.organization} />
                                        {formik.touched.organization && formik.errors.organization ? <p className='error'>{formik.errors.organization}</p> : null}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control type="email" id='email' placeholder="johndoe@example.com" onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.email : formik.values.email} />
                                        {formik.touched.email && formik.errors.email ? <p className='error'>{formik.errors.email}</p> : null}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Mobile Number</Form.Label>
                                        {/* <PhoneInput
                                            className='reg-control'
                                            placeholder="Enter mobile number"
                                            value={phoneNumber}
                                            onChange={setPhoneNumber}
                                        /> */}
                                        <Form.Control type="text" id='phone' placeholder="Enter phone number" onBlur={formik.handleBlur}
                                            onChange={formik.handleChange} value={state ? state?.phone : formik.values.phone} />
                                        {formik.touched.phone && formik.errors.phone ? <p className='error'>{formik.errors.phone}</p> : null}
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className='mb-3'>
                                        <Form.Label> City</Form.Label>
                                        <Form.Control type="text" id='city' placeholder="Enter city" onChange={formik.handleChange} value={formik.values.city} />
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control type="text" id='designation'
                                            placeholder='Enter Designation' onBlur={formik.handleBlur} onChange={formik.handleChange} value={state ? state?.designation : formik.values.designation} />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control type="text" id='address'
                                            placeholder='Enter Address here...' onBlur={formik.handleBlur} onChange={formik.handleChange} value={formik.values.address} />
                                    </Form.Group>
                                </Row>

                                <Form.Group className="mb-3 remember-me">
                                    <Form.Check type="checkbox" label="Keep me signed in" defaultChecked />
                                    {/* <p className="need-help"> <a href='#'> need help? </a></p> */}
                                </Form.Group>

                                <Button className='signup-btn w-100 mb-3' type="submit">
                                    Signup Now
                                </Button>

                                <div className="account">
                                    <p id='reg'>Already have an account? <span><Link to={"/login"}> login </Link></span></p>
                                </div>

                                {/* <Button className='brownBtn w-100' variant="primary" type="submit">
                                    Send Message
                                </Button> */}
                            </Form>
                        </Container>

                    </div>

                    <div className="footer-links2">
                        <ul>
                            <li><Nav.Link href='/#about-us'>About us</Nav.Link></li>
                            <li><NavLink to='/'>Privacy Policy</NavLink></li>
                            <li><NavLink to='/termsCondition'>Terms of use</NavLink></li>
                        </ul>
                        <div className="copy-right2">
                            <p>Copyright &copy; 2022. All Rights Reserved</p>
                        </div>
                    </div>

                </Col>
            </Row>


        </div >
    )
}

export default Registration