import productApi from "../../apis/productApi";
import { ActionTypes } from "../constants/action-types";


// dispatching the action to the reducer to create enquiry
export const sendEnquiry = (data: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post("/enquiry", { enquiry: { ...data } });
        dispatch({
            type: ActionTypes.CREATE_ENQUIRY_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.CREATE_ENQUIRY_FAIL,
            payload: error,
        });
    }
};


// using redux-thunk to make async calls to the API
export const fetchProducts = (searchBy?: any) => async (dispatch: any) => {
    const response = await productApi.get(`/product?limit=100&page=1&searchBy=${searchBy}`);
    // console.log(response.data.response.product.rows)
    dispatch({ type: ActionTypes.SET_PRODUCTS, payload: response?.data?.response?.product?.rows })
}

//this action creator will fetch a single product
export const fetchSingleProducts = (id: any) => async (dispatch: any) => {
    const response = await productApi.get("/product/" + id)
    dispatch({ type: ActionTypes.SELECTED_PRODUCT, payload: response?.data?.response?.product })
}

//this action creator will remove a single product 
export const removeSelectedProducts = () => {
    return {
        type: ActionTypes.REMOVE_SELECTED_PRODUCT,
    };
}

//this action creator will return a single product
export const selectedProducts = (products: any[]) => {
    return {
        type: ActionTypes.SELECTED_PRODUCT,
        payload: products,
    };
}




