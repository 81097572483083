// import { APICore } from 'helpers/api/apiCore';
// import { LoginState, Response } from "redux/types/DataTypes";
// import { ActionTypes as ActionTypes } from ".././constants/action-types";
import { ActionTypes } from "../constants/action-types";

// const api = new APICore();

const INIT_STATE = {
    loading: false,
    email: localStorage.getItem('email') || "",
    key: "",
    otp: "",
    isValidSession: false,
    error: null,
    session: {
        sessionId: localStorage.getItem('token'),
        privileges: [],
        role: '',
        userType: localStorage.getItem('userType'),
        userId: localStorage.getItem('userId'),
    },
    success: false,
    customer: {},
    verifySuccess: false,
    registeredCustomer: {},
    customerExist: {}
};


//! Login Reducer 
const loginReducer = (state = INIT_STATE, action: any) => {
    switch (action.type) {
        case ActionTypes.OTP_REQUEST_SUCCESS:
            // console.log(action.payload, "action.otp")
            // console.log(action.payload?.data?.response?.response, "customerExist.otp")
            return {
                ...state,
                loading: false,
                email: action.payload.email,
                key: action.payload?.data?.response?.key,
                error: null,
                success: true,

            };
        case ActionTypes.OTP_REQUEST_FAIL:
            // console.log(action.payload.error, "action.otp")
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                success: false

            };
        case ActionTypes.VERIFY_OTP_SUCCESS:
            return {
                ...state,
                loading: false,
                isValidSession: action.payload?.data?.response?.isValidSession,
                session: {
                    sessionId: action.payload?.data?.response?.session?.sessionId,
                    userType: action.payload?.data?.response?.session?.userType,
                    userId: action.payload?.data?.response?.userId
                },
                verifySuccess: true

            };
        case ActionTypes.VERIFY_OTP_FAIL:
            // console.log(action.payload.error.response.data.errorMsg)

            return {
                ...state,
                // loading: false,
                error: action?.payload?.error?.response?.data?.errorMsg,
                // success: false
            };
        case ActionTypes.EDIT_MAIL:
            return {
                ...state,
                key: "",
                email: "",
                isExist: false
            };
        case ActionTypes.LOGOUT:
            return {
                key: "",
                email: "",
                otp: "",
                error: null,
                isValidSession: false
            }
        case ActionTypes.GET_USER_DETAIL:
            // console.log(action.payload, "fix customer exist")
            // console.log(action.payload.data, "cust details")

            return {
                ...state,
                customer: action.payload.data,
                // customerExist: action?.payload?.response?.customer
                // customerExist: action.payload.data
            }
        case ActionTypes.GET_USER_DETAIL_FAIL:
            return {
                ...state,
                error: action.payload.error
            }
        case ActionTypes.CREATE_USER_SUCCESS:
            // console.log(action.payload, "action.payload")
            return {
                ...state,
                registeredCustomer: action.payload.data,
                error: null,
                success: false
            }
        case ActionTypes.CREATE_USER_FAIL:
            return {
                ...state,
                error: action?.payload?.error?.response?.data?.errorMsg,
            }
        case ActionTypes.FETCH_CUSTOMER:
            // console.log(action?.payload?.response?.customer, "fetch cust exist")
            return {
                ...state,
                customerExist: action?.payload?.response?.customer
            }
        case ActionTypes.FETCH_CUSTOMER_FAIL:
            return {
                ...state,
                error: action.payload.error
            }
        default:
            return { ...state };
    }
};

export default loginReducer;
