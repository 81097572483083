import productApi from "../../apis/productApi";
import { ActionTypes } from "../constants/action-types";


// const url = "http://localhost:5000/api/v1/login/"
// const url = "https://faraday.arachnomesh.com/api/v1/login/"


//action will make the request t0 get the otp
export const getOtpRequest = (email: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post("login/", { email })
        // console.log(response.data, "response.data")
        dispatch({
            type: ActionTypes.OTP_REQUEST_SUCCESS,
            payload: {
                data: response.data,
                email: email
            }
        })
    } catch (error: any) {        
        dispatch({
            type: ActionTypes.OTP_REQUEST_FAIL,
            payload: { error: error?.response?.data?.errorMsg }
        })
    }
}

//action will help in verifying the request the otp
export const verifyOtpRequest = (email: string, key: string, otp: string) => async (dispatch: any) => {

    try {
        const response = await productApi.post("login/otp", { email, key, otp })

        dispatch({
            type: ActionTypes.VERIFY_OTP_SUCCESS, payload: {
                data: response.data,
                email: email
            }
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.VERIFY_OTP_FAIL,
            payload: { error }
        })
    }
}

//action to fetch customer
export const fetchCustomer = (id: any) => async (dispatch: any) => {
    try {
        const response = await productApi.get(`/customer/${id}`)
        // console.log(response.data, "fetchCustomer")
        dispatch({
            type: ActionTypes.FETCH_CUSTOMER,
            payload: response?.data,
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.FETCH_CUSTOMER_FAIL,
            payload: { error }
        })
    }
}

//disptach to logout 
export const logout = () => async (dispatch: any) => {

    try {
        dispatch({
            type: ActionTypes.LOGOUT,
            payload: {}
        })
    } catch (error) {
        console.log(error);

    }
}

//! Get customer details

export const getCustomerDetails = (id: any) => async (dispatch: any) => {

    try {
        const response = await productApi.get(`/customer/${id}`)
        console.log(response.data, "getCustomerDetails")

        dispatch({
            type: ActionTypes.GET_USER_DETAIL, payload: {
                data: response.data,
            }
        })
    } catch (error) {
        dispatch({
            type: ActionTypes.GET_USER_DETAIL_FAIL,
            payload: { error }
        })
    }
}

//! Action to create/register new customer

export const createCustomer = (customer: any) => async (dispatch: any) => {
    try {
        const response = await productApi.post('/customer', { customer })
        dispatch({
            type: ActionTypes.CREATE_USER_SUCCESS,
            payload: {
                data: response.data
            }
        })

    } catch (error) {
        dispatch({
            type: ActionTypes.CREATE_USER_FAIL,
            payload: { error }
        })
    }
}


//action will be executed when otp is verified
// export const verifyOtpResponse = (actionType: string, data: Response) => ({
//     type: ActionTypes.VERIFY_OTP_SUCCESS,
//     payload: { actionType, data },
// });

//dispatch to verify otp
// export const verifyOtpRequest = (email: string, key: string, otp: string) => ({

//     type: ActionTypes.VERIFY_OTP_REQUEST,
//     payload: { email, key, otp },
// });

//dispatch to send otp request
// export const getOtpRequest = (email: string) => ({
//     type: ActionTypes.SEND_OTP_REQUEST,
//     payload: { email },
// });




//action to set key after successfully get the response
//need to change the datatype of data
// export const otpRequestResponse = (actionType: string, data: Response) => ({
//     type: ActionTypes.OTP_REQUEST_SUCCESS,
//     payload: { actionType, data },
// });

//action to set error
// export const otpErrorResponse = (actionType: string, error: any) => ({
//     type: ActionTypes.OTP_REQUEST_FAIL,
//     payload: { actionType, error },
// });





//action will be executed when otp is not verified
// export const verifyOtpError = (actionType: string, error: any) => ({
//     type: ActionTypes.VERIFY_OTP_FAIL,
//     payload: { actionType, error },
// });


//action to edit mail
// export const editMailAction = () => ({
//     type: ActionTypes.EDIT_MAIL,
//     payload: {},
// });
