/* eslint-disable */
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../redux/hook"
import { fetchSingleProducts, sendEnquiry } from "../../redux/actions/productActions"
import { useParams, useNavigate } from 'react-router-dom';
import useEnquiryValidation from './hooks/useEnquiryValidation';
import QuoteButton from '../Misc/QuoteButton';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { getCustomerDetails } from '../../redux/actions/loginActions';
import Table2 from './Table2';
// import ProductParam from '../ProductDetail/ProductParamt';
import NotifyToast from '../Misc/NotificationToast';
import { clearEnquiryId } from "../../redux/actions/enquiryActions"



function About() {
    const [show, setShow] = useState(false);
    const singleData = useAppSelector((state: any) => state.product.singleProductData);
    const dispatch = useAppDispatch();
    const { id } = useParams()

    const userEmail = localStorage.getItem("email")
    const session = localStorage.getItem("session")
    const userId = localStorage.getItem("userId")

    useEffect(() => {
        if (id) {
            dispatch(fetchSingleProducts(id))
            dispatch(getCustomerDetails(userId))
        }

    }, [id, userId, session])

    const customer = useAppSelector((state: any) => state.customerLogin);
    const [desc, setDesc] = useState("")
    const status = "Enquiry received"
    const description = desc
    const EnquiryType = "SKU"
    const productId = singleData?.id
    const custId = customer?.customer?.response?.customer?.id


    const sendEnquiryData = {
        customer_id: custId,
        product_id: productId,
        description: description,
        enquiry_type: EnquiryType,
        status: status,
        user_id: userId,
        doe: new Date(),
    }

    const navigate = useNavigate();

    const handleClose = () => {
        console.log("sendEnquiryData", sendEnquiryData)
        setShow(false);
        dispatch(sendEnquiry(sendEnquiryData))
    }

    const handleShow = () => setShow(true);

    const handleClick = () => {
        if (userEmail && session) {
            navigate(`/singleDetail/${id}`, { state: { id: id, form: "" } })
        } else {
            navigate("/login")
        }
    }

    useEffect(() => {
        const flag = localStorage.getItem("sFlag");
        if (flag) {
            setShow(true);
        }

    }, [])

    const productEnq = useAppSelector((state: any) => state?.enquiry?.productEnquiry);
    const enqId = productEnq?.response?.enquiry?.id

    useEffect(() => {
        if (enqId) {
            console.log(productEnq, "enqId")
            toast.success(<div className='link_toast'>
                {/* Enquiry sent successfully click here to view */}
                <NotifyToast />
            </div>, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 15000,
                closeOnClick: false,
                className: 'customNotifyToast',
            })

            dispatch(clearEnquiryId())
        }

    }, [enqId])


    return (

        <div className="AboutSec">
            <div >
                <div className="aboutProdct my-3">
                    {/* <h4> DESCRIPTION</h4>
                    <p>{singleData?.description}</p>
                    <h4 className='mt-5'>GENERAL PARAMETERS</h4> */}
                    {/* <p className='mb-1'><span className='text-black me-2'>{Allimages[paramId]?.desc1}</span></p>
                    <p><span className='text-black me-2'>{Allimages[paramId]?.desc2}</span> </p> */}

                    {/* <ProductParam item={singleData} /> */}
                    <div dangerouslySetInnerHTML={{ __html: singleData?.html_desc }} ></div>


                    {userEmail && session ? (
                        <>
                            <QuoteButton id={id} product={singleData} />
                        </>
                    ) : (
                        <Button className='brownBtn' variant="brownBtn" onClick={() => {
                            toast.info("Please login to continue")
                            // console.log("prdctId", id)
                            navigate("/login", { state: { id: id, form: "", sFlag: true } })
                        }
                        }>Request a quote</Button>
                    )}

                    {
                        singleData?.id === 1 ? (
                            <Table2 />
                        ) : (
                            <></>
                        )
                    }


                    {/* <Tab eventKey="INFORMATION" title="INFORMATION">
                    <div className="aboutProdct my-3">
                        <h4>Information content</h4>
                        <p>
                            Bolted Panel Zn-Alume tanks, MS Tanks, SS Tanks etc. from 2 KL to 10000 KL as per IS, ANSI, DIN for raw and fire water, Molasses, DM water, Fuel, Consumable Liquid etc.
                            Bolted Panel Zn-Alume tanks, MS Tanks, SS Tanks etc. from 2 KL to 10000 KL as per IS, ANSI, DIN for raw and fire water, Molasses, DM water, Fuel, Consumable Liquid etc.
                            Bolted Panel Zn-Alume tanks, MS Tanks, SS Tanks etc. from 2 KL to 10000 KL as per IS, ANSI, DIN for raw and fire water, Molasses, DM water, Fuel, Consumable Liquid etc.

                        </p>
                    </div>
                </Tab> */}

                    {/* <Tab eventKey="REVIEWS" title="REVIEWS">
                    <div className="aboutProdct my-3">
                        <h4> Review here</h4>
                        <p>
                            reviews shows here
                        </p>
                    </div>
                </Tab> */}

                    {/* <Tab eventKey="PRODUCT ENQUIRY" title="PRODUCT ENQUIRY">
                    <div className="aboutProdct my-3">

                        <Row style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",

                        }}>
                            <h1>{productData?.name}</h1>
                            <h1 style={{ "fontSize": "11px" }}>{productData?.description}</h1>
                        </Row>

                        {Jsonform && <GetForm baseFormSchema={Jsonform} />}
                        <h4> PRODUCT ENQUIRY</h4>
                        <p>
                            You can send product enquiries from here
                        </p>
                        <div className="contactForm">
                            <h3>forms shown here
                            </h3>
                        </div>
                    </div>
                </Tab > */}

                    {/* </Tabs > */}
                </div>
            </div>
        </div>
    )
}

export default About; 