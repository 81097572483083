/* eslint-disable */
import { useEffect, useState } from 'react'
import "../../assets/css/customClass.css"
import "../../assets/css/main.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import logo2 from "../../assets/img/logo.png"
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux/hook"
import useLogin from "./hooks/useLogin";
import { toast } from "react-toastify";
import { NavLink } from 'react-router-dom';
// import Nav from 'react-bootstrap/Nav';



function Otp({ rememberMe, id }: { rememberMe: boolean, id: any }) {
    console.log(id, "idinotp")

    const { key, isValidSession, email, verifySuccess } = useAppSelector((state: any) => state.customerLogin);

    const { verifyOtp, sendSuccess, setSuccess, setSessionStorage, setLocalStorage } = useLogin();

    const [otp, setOtp] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        if (sendSuccess) {
            toast.success("OTP sent successfully");
            setSuccess(false);
        }
        if (verifySuccess) {
            toast.success("Logged in successfully");

        }

    }, [sendSuccess, verifySuccess]);

    useEffect(() => {
        if (id && verifySuccess) {

            // console.log(`/singleDetail/${id}`, "`/singleDetail/${id}`")
            window.location.href = `/singleDetail/${id}`
            // navigate("/singleDetail/" + id)
        }
    }, [id, verifySuccess])


    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (!otp) {
            toast.error("Please enter OTP");
        } else {
            verifyOtp(otp, email, key);
        }
    }

    useEffect(() => {
        // console.log(rememberMe, "rem me")
        if (isValidSession) {
            if (!rememberMe) {
                setSessionStorage()
            } else {
                setLocalStorage()
            }
            navigate("/");
        }

        // eslint-disable-next-line
    }, [isValidSession, rememberMe])


    return (

        <div className="login-container2">
            <div className="main-container">
                <div className="login__logo2">
                    <NavLink to='/'> <img src={logo2} alt='logo' /></NavLink>
                </div>
                <div className='form-holder'>
                    <Container className='form-container2'>
                        <Form>
                            <h2>Enter OTP</h2>
                            <p id='welcome'>We sent a 6 digit OTP to your email id!</p>
                            <Form.Group className="mb-3" >
                                {/* <Form.Label>OTP</Form.Label> */}
                                <Form.Control className='email-input2' type="email" onChange={e => setOtp(e.target.value)} />
                            </Form.Group>

                            {/* <Form.Group className="mb-3 remember-me" controlId="formBasicCheckbox">
                                <Form.Check type="checkbox" label="Remember me" />
                                <p className="need-help"> <a href='#'> need help? </a></p>
                            </Form.Group> */}
                            <button className='brown-btn2' type='submit' onClick={handleSubmit}>
                                Submit OTP
                            </button>

                            {/* <p id='register'>Don't have an account? <span id='reg'><a href='#'> Register</a></span></p> */}

                        </Form>
                    </Container>

                </div>
                <div className="footer-links2">
                    <ul>
                        <li><NavLink to='/'>About us</NavLink></li>
                        <li><NavLink to='/'>Privacy Policy</NavLink></li>
                        <li><NavLink to='/termsCondition'>Terms of use</NavLink></li>
                    </ul>

                    <div className="copy-right2">
                        <p>Copyright &copy; 2022. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div >



    )
}

export default Otp