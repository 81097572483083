
// import { Allimages } from "../Misc/AllProductDetails"
// import ProductParam from './ProductParamt';
import { baseUrl } from '../../constants/url';
// import defaultImg from "../../assets/img/noproduct.png"
import defaultImg from "../../assets/img/Noproducts/noproduct.webp"

interface Props {
    item: any,
    id: any,
}
const MAX_LENGTH = 220;
function ProductSliderCard({ item, id }: Props) {
    const myString = item.html_desc;
    const div = document.createElement('div');
    div.innerHTML = myString;
    const text = div.textContent;
    item.text=text
    return (

        <div className="productCard" key={id}>
            <a href={`/singleDetail/${item.id}`}>
                <img src={item?.productImage?.fullPath ? `${baseUrl}` + item?.productImage?.fullPath : defaultImg} alt='img' /></a>
            <div className="details">
                <div className="detailsHeading">
                    <a href={`/singleDetail/${item.id}`}><h5>{item.name}</h5> </a>
                    {/* <span className="availablity">In Stock</span> */}
                </div>
                <div className="contentDiv">
                    <div className='contnt'>
                        {/* <div className="description">
                            <h5>PRODUCT DESCRIPTION</h5>
                            <p>{item.description}</p>
                        </div> */}

                                                {item.text.length< MAX_LENGTH ?
                                                (
                                                    <div  dangerouslySetInnerHTML={{ __html: item.html_desc }} ></div>
                                                )
                                                :
                                                <div className='hoverContent'>
                                                    <div className='custom_9' title={item.text} dangerouslySetInnerHTML={{ __html: item.html_desc }} > 
                                                    </div>
                                                  <span className='dot'>.....</span>
                                                </div>

                                            }
                    </div>
                    {/* <div className="btnDiv">
                        <QuoteButton id={item.id - 1} product={""} />
                    </div> */}
                </div>

            </div>
        </div>

    )
}

export default ProductSliderCard
