export const ActionTypes = {

    // Product Actions
    FETCH_PRODUCTS: 'FETCH_PRODUCTS',
    SELECTED_PRODUCT: "SELECTED_PRODUCT",
    REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
    SET_PRODUCTS: "SET_PRODUCTS",

    //Login Actions
    SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
    OTP_REQUEST_SUCCESS: "OTP_REQUEST_SUCCESS",
    OTP_REQUEST_FAIL: "OTP_REQUEST_FAIL",
    VERIFY_OTP_REQUEST: "VERIFY_OTP_REQUEST",
    VERIFY_OTP_SUCCESS: "VERIFY_OTP_SUCCESS",
    VERIFY_OTP_FAIL: "VERIFY_OTP_FAIL",
    EDIT_MAIL: "EDIT_MAIL",
    LOGOUT: "LOGOUT",

    //Query Actions
    ADD_QUERY: "ADD_QUERY",
    QUERY_SUCCESS: "QUERY_SUCCESS",
    QUERY_FAIL: "QUERY_FAIL",

    //User Actions
    GET_USER_DETAIL: "GET_USER_DETAIL",
    GET_USER_DETAIL_FAIL: "GET_USER_DETAIL_FAIL",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAIL: "CREATE_USER_FAIL",

    //Equiry Actions
    CREATE_ENQUIRY_SUCCESS: "CREATE_ENQUIRY_SUCCESS",
    CREATE_ENQUIRY_FAIL: "CREATE_ENQUIRY_FAIL",
    CLEAR_ENQUIRY_ID: "CLEAR_ENQUIRY_ID",

    //File Upload Actions
    UPLOAD_FILE: "UPLOAD_FILE",
    UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
    UPLOAD_FILE_FAIL: "UPLOAD_FILE_FAIL",

    //Customer Actions
    FETCH_CUSTOMER: "FETCH_CUSTOMER",
    SET_CUSTOMER: "SET_CUSTOMER",
    FETCH_CUSTOMER_FAIL: "FETCH_CUSTOMER_FAIL",


}
