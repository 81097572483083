/* eslint-disable */
import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook";
import { fetchSingleProducts } from "../../../redux/actions/productActions";
import { Location, useLocation } from "react-router-dom";


export default function useForm() {

    const dispatch = useAppDispatch();
    const productData = useAppSelector((state: any) => state.product.singleProductData);

    const location: Location = useLocation();
    const { state } = location;
    const { id, form } = state;

    console.log(form, id, "state")
    useEffect(() => {
        if (id) {

            dispatch(fetchSingleProducts(id))
        }
    }, [id])

    const [Jsonform, setJsonform] = useState<any>(null);

    useEffect(() => {
        if (form) {
            setJsonform(form.custom_form)
        }
    }, [form])

    return { productData, Jsonform }
}