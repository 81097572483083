/* eslint-disable */
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap';
import Lightbox from 'react-18-image-lightbox'
import 'react-18-image-lightbox/style.css'; // This only needs to be imported once in your app




function Table2() {

    let r = null;
    let c = null;

    const [toggle, setToggle] = useState(false)

    const [_image, setImage] = useState("https://faraday-admin.arachnomesh.com/uploads/enquiry/image/mainimg.jpg")

    const setLightBoxView = () => {
        setImage("https://faraday-admin.arachnomesh.com/uploads/enquiry/image/mainimg.jpg")
        setToggle(true)


    }

    const [isActive, setActive] = useState(false);
    const [selectedCell, setSelectedCell] = useState([-1, -1]);

    const toggleClass = (row: number, col: number) => {
        if (selectedCell[0] === row && selectedCell[1] === col) {
            setSelectedCell([-1, -1])
            return
        }

        localStorage.setItem("r", row.toString())
        localStorage.setItem("c", col.toString())
        localStorage.setItem("sFlag", "true")

        setSelectedCell([row, col])


        // else {
        //     setSelectedCell([row, col])
        // }


    };

    useEffect(() => {

        r = localStorage.getItem("r")
        c = localStorage.getItem("c")
        if (r && c) {
            console.log(typeof r, "rowlocal", typeof parseInt(r))
            setSelectedCell([parseInt(r), parseInt(c)])
        }
    }, [r, c])



    return (
        <>
            <div id='lightbox'>
                {
                    toggle && <Lightbox
                        mainSrc={_image || ""}
                        clickOutsideToClose={true}
                        onCloseRequest={() => setToggle(false)}

                    />
                }
            </div>

            <div className='table-container table-responsive'>
                <Table bordered className='dataCustomTable'>
                    <thead>
                        <tr className='bg_light_blue'>
                            <th colSpan={2}>SHEET/RING</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                        </tr>
                        <tr className='bg_sky_blue'>
                            <th colSpan={2}>Model nos.</th>
                            <th>MG001</th>
                            <th>MG002</th>
                            <th>MG003</th>
                            <th>MG004</th>
                            <th>MG005</th>
                            <th>MG006</th>
                            <th>MG007</th>
                            <th>MG008</th>
                            <th>MG009</th>
                            <th>MG010</th>
                        </tr>
                        <tr className='bg_green'>
                            <th colSpan={2}>Diameter</th>
                            <th>2.72</th>
                            <th>3.63</th>
                            <th>4.54</th>
                            <th>5.45</th>
                            <th>6.35</th>
                            <th>7.261</th>
                            <th>8.17</th>
                            <th>9.08</th>
                            <th>9.98</th>
                            <th>10.89</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr>
                            <td className='bg_dark_yellow'>Nos of Rings</td>
                            <td className='bg_light_yellow'>Tank Heights</td>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-1</td>
                            <td className='bg_light_yellow'>1.12</td>
                            {/* <td className={selectedCell[0] === 0 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 0) ,setLightBoxView()}}><a onClick={() => { setLightBoxView() }} href="#" > 3.73</a></td> */}
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 0), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 3.73</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 1), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 6.63</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 2), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 10.36</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 3), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 14.92</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 4), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 20.31</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 5), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 26.53</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 6), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 33.58</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 7), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 41.45</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 8), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 50.16</a></td>
                            <td className={selectedCell[0] === 0 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(0, 9), setLightBoxView() }}><a onClick={() => { setLightBoxView() }} href="#" > 59.69</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-2</td>
                            <td className='bg_light_yellow'>2.09</td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >9.38</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >16.67</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >26.05</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >37.51</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >51.05</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >66.68</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >84.39</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >104.18</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >126.06</a></td>
                            <td className={selectedCell[0] === 1 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(1, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >150.02</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-3</td>
                            <td className='bg_light_yellow'>3.06</td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >15.02</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >26.71</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >41.73</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >60.09</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >81.79</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >106.82</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >135.20</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >166.91</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >201.96</a></td>
                            <td className={selectedCell[0] === 2 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(2, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >240.35</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-4</td>
                            <td className='bg_light_yellow'>4.03</td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >20.67</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >36.74</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >57.41</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >82.67</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >112.52</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >146.97</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >186.01</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >229.64</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >277.87</a></td>
                            <td className={selectedCell[0] === 3 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(3, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >330.68</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-5</td>
                            <td className='bg_light_yellow'>5</td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >26.31</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >46.78</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >73.09</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >105.25</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >143.26</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >187.12</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >236.82</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >292.37</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >353.77</a></td>
                            <td className={selectedCell[0] === 4 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(4, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >421.01</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-6</td>
                            <td className='bg_light_yellow'>5.97</td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >31.96</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >56.82</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >88.78</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >127.84</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >174.00</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >227.26</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >287.63</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >355.10</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >429.67</a></td>
                            <td className={selectedCell[0] === 5 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(5, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >511.35</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-7</td>
                            <td className='bg_light_yellow'>6.94</td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >37.60</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >66.85</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >104.46</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >150.42</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >204.74</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >267.41</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >338.44</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >417.83</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >505.57</a></td>
                            <td className={selectedCell[0] === 6 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(6, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >601.68</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-8</td>
                            <td className='bg_light_yellow'>7.91</td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >43.25</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >76.89</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >120.14</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >173.00</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >235.47</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >307.56</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >389.25</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >480.56</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >581.48</a></td>
                            <td className={selectedCell[0] === 7 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(7, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >692.01</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-9 </td>
                            <td className='bg_light_yellow'>8.88 </td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >48.90</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >86.93</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >135.82</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >195.58</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >266.21</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >347.71</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >440.06</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >543.29</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >657.38</a></td>
                            <td className={selectedCell[0] === 8 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(8, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >782.34</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-10 </td>
                            <td className='bg_light_yellow'>9.85 </td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >54.54</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >66.96</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >151.50</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >218.17</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >296.95</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >387.85</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >490.88</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >606.02</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >733.28</a></td>
                            <td className={selectedCell[0] === 9 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(9, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >872.67</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'> H-11  </td>
                            <td className='bg_light_yellow'> 10.82 </td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >60.19</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >107.00</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >167.19</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >240.75</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >327.69</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >428.00</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >541.69</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >668.75</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >809.19</a></td>
                            <td className={selectedCell[0] === 10 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(10, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >963.00</a></td>
                        </tr>
                    </tbody>
                </Table>

                <Table bordered className='dataCustomTable'>
                    <thead>
                        <tr className='bg_light_blue'>
                            <th colSpan={2}>SHEET/RING</th>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                            <th>16</th>
                            <th>17</th>
                            <th>18</th>
                            <th>19</th>
                            <th>20</th>
                            <th>21</th>
                            <th>22</th>
                        </tr>
                        <tr className='bg_sky_blue'>
                            <th colSpan={2}>Model noS.</th>
                            <th>MG011</th>
                            <th>MG012</th>
                            <th>MG013</th>
                            <th>MG014</th>
                            <th>MG015</th>
                            <th>MG016</th>
                            <th>MG017</th>
                            <th>MG018</th>
                            <th>MG019</th>
                            <th>MG020</th>

                        </tr>
                        <tr className='bg_green'>
                            <th colSpan={2}>Diameter</th>
                            <th>11.80</th>
                            <th>12.71</th>
                            <th>13.61</th>
                            <th>14.52</th>
                            <th>15.43</th>
                            <th>16.34</th>
                            <th>17.25</th>
                            <th>18.15</th>
                            <th>19.06</th>
                            <th>19.97</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='bg_dark_yellow'>Nos of Rings</td>
                            <td className='bg_light_yellow'>Tank Heights</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-1</td>
                            <td className='bg_light_yellow'>1.12</td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 0), setLightBoxView() }} ><a onClick={() => setLightBoxView()} href="#" >70.06</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >81.25</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >93.27</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >106.12</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >119.80</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >134.31</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >149.65</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >165.81</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >182.81</a></td>
                            <td className={selectedCell[0] === 11 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(11, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >200.63</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-2</td>
                            <td className='bg_light_yellow'>2.09</td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >176.07</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >204.20</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >234.41</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >266.71</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >301.09</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >337.55</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >376.10</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >416.73</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >459.45</a></td>
                            <td className={selectedCell[0] === 12 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(12, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >504.24</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-3</td>
                            <td className='bg_light_yellow'>3.06</td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >282.08</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >327.15</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >375.55</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >427.30</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >482.38</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >540.80</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >602.55</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >667.65</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >736.08</a></td>
                            <td className={selectedCell[0] === 13 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(13, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >807.86</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-4</td>
                            <td className='bg_light_yellow'>4.03</td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >388.09</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >450.10</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >516.69</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >587.88</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >663.66</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >744.04</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >829.01</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >918.57</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1012.72</a></td>
                            <td className={selectedCell[0] === 14 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(14, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1111.47</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-5</td>
                            <td className='bg_light_yellow'>5</td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >494.11</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >573.05</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >657.84</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >748.47</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >844.95</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >947.28</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1055.46</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1169.48</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1289.36</a></td>
                            <td className={selectedCell[0] === 15 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(15, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1415.08</a></td>
                        </tr>

                        <tr>
                            <td className='bg_dark_yellow'>H-6</td>
                            <td className='bg_light_yellow'>5.97</td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >600.12</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >696.00</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >798.98</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >909.06</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1026.24</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1150.53</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1281.91</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1420.40</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1565.99</a></td>
                            <td className={selectedCell[0] === 16 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(16, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1718.69</a></td>
                        </tr>

                        <tr>
                            <td className='bg_dark_yellow'>H-7</td>
                            <td className='bg_light_yellow'>6.94</td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >706.13</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >818.95</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >940.12</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1069.65</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1207.53</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1353.77</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1508.37</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1671.32</a></td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1842.63</a> </td>
                            <td className={selectedCell[0] === 17 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(17, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2022.30</a></td>
                        </tr>

                        <tr>
                            <td className='bg_dark_yellow'>H-8</td>
                            <td className='bg_light_yellow'>7.91</td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >812.15</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >941.90</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1081.26</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1230.23</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1388.82</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1557.01</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1734.82</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1922.24</a></td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2119.27</a> </td>
                            <td className={selectedCell[0] === 18 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(18, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2325.91</a></td>
                        </tr>

                        <tr>
                            <td className='bg_dark_yellow'>H-9 </td>
                            <td className='bg_light_yellow'>8.88 </td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >918.16</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1064.85</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1222.40</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1390.82</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1570.11</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1760.26</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1961.27</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2173.16</a></td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2395.91</a> </td>
                            <td className={selectedCell[0] === 19 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(19, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2629.52</a></td>
                        </tr>


                        <tr>
                            <td className='bg_dark_yellow'>H-10 </td>
                            <td className='bg_light_yellow'>9.85 </td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1024.17</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1187.80</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1363.54</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1551.41</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1751.39</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1963.50</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2187.73</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2424.07</a> </td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2672.54</a></td>
                            <td className={selectedCell[0] === 20 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(20, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2933.13</a></td>
                        </tr>

                        <tr>
                            <td className='bg_dark_yellow'> H-11  </td>
                            <td className='bg_light_yellow'> 10.82 </td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1130.18</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1310.75</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1504.68</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1712.00</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1932.68</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2166.74</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2414.18</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2674.99</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2949.18</a></td>
                            <td className={selectedCell[0] === 21 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(21, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3236.74</a></td>
                        </tr>
                    </tbody>
                </Table>

                <Table bordered className='dataCustomTable'>
                    <thead>
                        <tr className='bg_light_blue'>
                            <th colSpan={2}>SHEET/RING</th>
                            <th>23</th>
                            <th>24</th>
                            <th>25</th>
                            <th>26</th>
                            <th>27</th>
                            <th>28</th>
                            <th>29</th>
                            <th>30</th>
                            <th>31</th>
                            <th>32</th>
                        </tr>
                        <tr className='bg_sky_blue'>
                            <th colSpan={2}>Model noS.</th>
                            <th>MG021</th>
                            <th>MG022</th>
                            <th>MG023</th>
                            <th>MG024</th>
                            <th>MG025</th>
                            <th>MG026</th>
                            <th>MG027</th>
                            <th>MG028</th>
                            <th>MG029</th>
                            <th>MG030</th>
                        </tr>
                        <tr className='bg_green'>
                            <th colSpan={2}>Diameter</th>
                            <th>20.88</th>
                            <th>21.78</th>
                            <th>22.69</th>
                            <th>23.60</th>
                            <th>24.51</th>
                            <th>25.41</th>
                            <th>26.32</th>
                            <th>27.23</th>
                            <th>28.14</th>
                            <th>29.04</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className='bg_dark_yellow'>Nos of Rings</td>
                            <td className='bg_light_yellow'>Tank Heights</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-1</td>
                            <td className='bg_light_yellow'>1.12</td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >219.29</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >238.77</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >259.08</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >280.22</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >302.19</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >324.99</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >348.62</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >373.08</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >398.37</a></td>
                            <td className={selectedCell[0] === 22 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(22, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >424.48</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-2</td>
                            <td className='bg_light_yellow'>2.09</td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >551.13</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >600.09</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >651.14</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >704.28</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >759.49</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >816.79</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >876.18</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >937.64</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1001.20</a></td>
                            <td className={selectedCell[0] === 23 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(23, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1066.83</a></td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-3</td>
                            <td className='bg_light_yellow'>3.06</td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >882.97</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >961.41</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1043.20</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1128.33</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1215.79</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1308.59</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1403.73</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1502.21</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1604.03</a> </td>
                            <td className={selectedCell[0] === 24 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(24, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1709.18</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-4</td>
                            <td className='bg_light_yellow'>4.03</td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1214.80</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1322.74</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1435.26</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1552.38</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1674.09</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1800.39</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1931.29</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2066.78</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2206.80</a> </td>
                            <td className={selectedCell[0] === 25 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(25, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2351.53</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-5</td>
                            <td className='bg_light_yellow'>5</td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1546.64</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1684.06</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1827.32</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1976.43</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2131.39</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2292.19</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2458.81</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2631.34</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2809.69</a> </td>
                            <td className={selectedCell[0] === 26 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(26, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2993.88</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-6</td>
                            <td className='bg_light_yellow'>5.97</td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >1878.48</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2045.38</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2219.38</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2400.48</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2588.68</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2783.99</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2986.40</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3195.91</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3412.52</a> </td>
                            <td className={selectedCell[0] === 27 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(27, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3636.23</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-7</td>
                            <td className='bg_light_yellow'>6.94</td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2210.32</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2406.70</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2611.44</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2824.53</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3045.98</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3275.79</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3513.95</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3760.47</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4015.35</a> </td>
                            <td className={selectedCell[0] === 28 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(28, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4278.58</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-8</td>
                            <td className='bg_light_yellow'>7.91</td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 2542.16</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 2768.02</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 3003.50</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 3248.58</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 3503.28</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 3767.59</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4041.51</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4325.04</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4618.18</a> </td>
                            <td className={selectedCell[0] === 29 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(29, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4920.93</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-9 </td>
                            <td className='bg_light_yellow'>8.88 </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >2874.00</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3129.35</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3395.56</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3672.63</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3960.58</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4259.39</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4569.06</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4889.60</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >5221.01</a> </td>
                            <td className={selectedCell[0] === 30 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(30, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >5563.28</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'>H-10 </td>
                            <td className='bg_light_yellow'>9.85 </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3205.84</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3490.67</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >3787.62</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4096.69</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4417.88</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >4751.19</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >5096.62</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >5454.17</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >5823.84</a> </td>
                            <td className={selectedCell[0] === 31 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(31, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" >6205.63</a> </td>
                        </tr>
                        <tr>
                            <td className='bg_dark_yellow'> H-11  </td>
                            <td className='bg_light_yellow'> 10.82 </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 0 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 0), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 3537.68</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 1 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 1), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 3851.99</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 2 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 2), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4179.68</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 3 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 3), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4520.74</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 4 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 4), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 4875.17</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 5 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 5), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 5242.99</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 6 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 6), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 5624.17</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 7 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 7), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 6018.73</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 8 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 8), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 6426.67</a> </td>
                            <td className={selectedCell[0] === 32 && selectedCell[1] === 9 ? 'active-ptab' : undefined} onClick={() => { toggleClass(32, 9), setLightBoxView() }}><a onClick={() => setLightBoxView()} href="#" > 6847.98</a> </td>
                        </tr>
                    </tbody>
                </Table>

            </div >
        </>
    )
}

export default Table2