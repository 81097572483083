import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
// import LinkContainer from 'react-bootstrap/LinkContainer';
import logo from "../assets/img/logo.png";
// import search from "../assets/img/search.png";
// import cart from "../assets/img/cart.png";
// import bar from "../assets/img/bar.png";
// import { Form } from 'react-bootstrap';
// import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hook';
import { logout } from '../redux/actions/loginActions';
import { toast } from 'react-toastify';

import user1 from '../assets/img/user.png';

// import { Link } from 'react-scroll';

function Header() {

    const [uname, setUname] = useState("Log In");
    // const [login, setLogin] = useState(false);
    const { email } = useAppSelector((state: any) => {
        return state.customerLogin
    }
    );

    const urlMapping: any = {
        "fb.arachnomesh.com": "faraday-admin.arachnomesh.com",
        "fb-demo.arachnomesh.com": "fb-admin.arachnomesh.com",
        "fabricationbazar.com": "admin.fabricationbazar.com",
        "localhost:3001": "localhost:3000"
    }
    // const email = useAppSelector((state: any) => state.customerLogin.email);
    const dispatch = useAppDispatch();
    const userLogin = sessionStorage.getItem("email") || localStorage.getItem("email")
    const userSession = sessionStorage.getItem("session") || localStorage.getItem("session")

    const logoutHandler = () => {
        sessionStorage.clear();
        localStorage.removeItem("email");
        localStorage.removeItem("session");
        localStorage.removeItem("key");
        localStorage.removeItem("userId");
        localStorage.removeItem("r");
        localStorage.removeItem("c");
        localStorage.removeItem("sFlag");
        dispatch(logout());
        toast.success("Logged out successfully");
    }

    useEffect(() => {
        if (userLogin && userSession) {
            setUname(email.slice(0, email.indexOf("@")));
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Navbar expand="lg" className='header'>
            <Navbar.Brand>
                <div className='logo'>
                    <Link to="/"> <img src={logo} alt="logo" /></Link>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    {/* <Nav.Link href="#home">  Home</Nav.Link> */}
                    <Nav.Link href="/" className="nav-link"> Home</Nav.Link>
                    {/* <Nav.Link href="#link">Link</Nav.Link> */}

                    <Nav.Link href="/productDetail" className="nav-link"> Products</Nav.Link>

                    {/* <NavDropdown title="Products" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Products 1</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">
                            Products 2
                        </NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.3">Products 3</NavDropdown.Item>
                    </NavDropdown> */}

                    {/* <Element id='about-us' name='about-us'> */}
                    <Nav.Link href='/#about-us' className="nav-link" style={{ color: "#fff" }}> About us</Nav.Link>
                    {/* </Element> */}
                    {/* <Nav.Link href="#about-us">About us</Nav.Link> */}

                    <Nav.Link href="/#contact-us" className="nav-link" style={{ color: "#fff" }}>Contact us</Nav.Link>

                    {/* <Nav.Link href="#link">
                        <div className="tobarSearch">
                            <Form.Control type="text" placeholder='Search here..' />

                        </div>

                    </Nav.Link> */}

                    {/* <Nav.Link href="#link" className='d-flex'> <span><img src={user} className='userIcon' alt="user" /></span> */}
                    {/* </Nav.Link> */}
                    {userLogin && userSession ? (
                        <>
                        <a href={`http://${urlMapping[window.location.host]}/login?source=${window.location.host}&sid=${userSession}`} target="_blank" rel="noreferrer" className="nav-link">Dashboard</a>
                            <div className='d-flex align-items-center'>
                                <span><img src={user1} className='userIcon' alt="user" /></span>
                                <NavDropdown title={uname}>
                                    <NavDropdown.Item href="/" onClick={logoutHandler}>Logout</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </>
                    ) : (
                        <>
                        <Nav.Link href='/login' className='btn brownBtn mx-3'>Login</Nav.Link>
                        <Nav.Link href='/register' className='btn borderBtn'>Sign up</Nav.Link>
                        
                        </>
                    )}

                </Nav>
            </Navbar.Collapse>


        </Navbar >

    )
}

export default Header
