
import useForm from './hooks/useForm'
import { Container, Row,  } from 'react-bootstrap'
import { BaseForm, JsonSchemaParser } from '@arachnomesh/forms';



function CustomForms() {
    const { productData, Jsonform } = useForm()

    // console.log(productData);
    // const location = useLocation();
    // console.log(location.state.form, "state custom form mein");
    // const formData = location.state.form
    // console.log(formData.custom_form.fields, "fields hai ye")
    // useEffect(() => {
    //     if (Jsonform) {
    //         schema = new JsonSchemaParser(Jsonform);
    //         //  e.log(schema, "schema------");
    //     }
    // }, [Jsonform])

    const GetForm = ({ baseFormSchema, onSubmitHandler }: any) => {
        let schema = new JsonSchemaParser(baseFormSchema);
        return (
            <BaseForm schema={schema} onSubmit={(val: any) => console.log(val)}>
                <button type="submit" className="btn btn-primary">Submit form</button>
                {/* <button className="m-2 btn btn-danger">Cancel</button> */}
            </BaseForm>
        )
    }

    // const userData = {
    //     organization: 'Arachnomesh Tech',
    //     description: 'This is the description of product',
    //     status: 'Enquiry received'
    // }

    return (
        <>
            <Container className='contactus' >
                <Row style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",

                }}>
                    <h1>{productData?.name}</h1>
                    <h1 style={{ "fontSize": "11px" }}>{productData?.description}</h1>
                </Row>



                {Jsonform && <GetForm baseFormSchema={Jsonform} />}


            </Container >

        </>
    )
}

export default CustomForms